import React, { useEffect, useState } from 'react';
import { Button, ButtonGroup, MenuItem } from '@mui/material';
import { listItemStyle, textFieldStyle } from '../../../../utils/consts/styles';
import Filter from '../../Searchbar/Search/Filter';
import TextField from '@mui/material/TextField';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import DatePicker from "react-datepicker";
import MaskedInput from 'react-text-mask/dist/reactTextMask';
import jwt_decode from 'jwt-decode';
import { getToken } from '../../../../utils/token';
import { getFilteredItems } from './getFilteredItems';
import HoursNumberComponent from '../../Contract/HoursNumberComponent/HoursNumberComponent';
import Box from '@mui/material/Box';

const TableSearchComponent = (
  {
    loading,
    studentList,
    setFilteredValues
  }) => {
  const decodedToken = jwt_decode(getToken());
  const isTeacherAccess = decodedToken.role === 'Преподаватель';

  const [searchType, setSearchType] = useState('');
  const [searchingValue, setSearchingValue] = useState('');
  const [filterCondition, setFilteredCondition] = useState('latin_name');
  const [filters, setFilters] = useState([]);

  useEffect(() => {
    let filteredValues = studentList.filter(row => {
      if (searchType === 'filter')
        return getFilteredItems(row, filters);
      else if (searchType === 'search' && filterCondition === "birth_date") {
        if (searchingValue === '')
          return row;
        else
          return new Date(row[filterCondition]).setHours(0, 0, 0) === new Date(searchingValue).setHours(0, 0, 0);
      } else if (searchType === 'search' || searchType === 'program') {
        if (row[filterCondition] === null || row[filterCondition] === '')
          return;
        else return row[filterCondition].toLowerCase().includes(searchingValue.toLowerCase());
      } else
        return row;
    });

    setFilteredValues(filteredValues);
  }, [searchingValue, filters]);

  if (!loading)
    return (
      <div className="tableSearchBlockPosition">
        <ButtonGroup
          variant="outlined"
          aria-label="outlined button group"
          sx={{ height: '40px' }}
        >
          {!isTeacherAccess && <Button
            color="warning"
            sx={listItemStyle}
            onClick={() => {
              setSearchingValue('');
              setSearchType('filter');
            }}
          >
            Фильтрация
          </Button>}
          <Button
            color="warning"
            sx={listItemStyle}
            onClick={() => {
              setSearchingValue('');
              setFilteredCondition('latin_name');
              setSearchType('search');
            }}
          >
            Поиск
          </Button>
          <Button
            color="warning"
            sx={listItemStyle}
            onClick={() => {
              setSearchingValue('');
              setFilteredCondition('hours_number');
              setSearchType('program');
            }}
          >
            Программа
          </Button>
        </ButtonGroup>
        <div className="searchPosition">
          {searchType === 'filter' && !isTeacherAccess &&
            <Filter filters={filters} setFilters={setFilters} />
          }
          {searchType === 'search' &&
            <div className="searchType">
              <TextField
                label="Поиск"
                type="text"
                margin="normal"
                variant="outlined"
                color="warning"
                size="small"
                select
                InputLabelProps={textFieldStyle}
                value={filterCondition}
                sx={{ width: '200px', mt: 0, mr: "20px", mb: 0 }}
                onChange={e => {
                  setSearchingValue('');
                  setFilteredCondition(e.target.value);
                }}
              >
                <MenuItem sx={textFieldStyle} value="latin_name">
                  <span style={listItemStyle}>ФИО (лат.)</span>
                </MenuItem>
                <MenuItem sx={textFieldStyle} value="russian_name">
                  <span style={listItemStyle}>ФИО (кир.)</span>
                </MenuItem>
                {isTeacherAccess && <MenuItem sx={textFieldStyle} value="gender">
                  <span style={listItemStyle}>Пол</span>
                </MenuItem>}
                {isTeacherAccess && <MenuItem sx={textFieldStyle} value="form_study">
                  <span style={listItemStyle}>Форма обучения</span>
                </MenuItem>}
                {isTeacherAccess && <MenuItem sx={textFieldStyle} value="education_type">
                  <span style={listItemStyle}>Тип обучения</span>
                </MenuItem>}
                {!isTeacherAccess && <MenuItem sx={textFieldStyle} value="passport_number">
                  <span style={listItemStyle}>Номер паспорта</span>
                </MenuItem>}
                {!isTeacherAccess && <MenuItem sx={textFieldStyle} value="birth_date">
                  <span style={listItemStyle}>Дата рождения</span>
                </MenuItem>}
                {isTeacherAccess && <MenuItem sx={textFieldStyle} value="registered_in_system">
                  <span style={listItemStyle}>Дата регистрации на ПОдИУ</span>
                </MenuItem>}
                <MenuItem sx={textFieldStyle} value="country">
                  <span style={listItemStyle}>Страна</span>
                </MenuItem>
                <MenuItem sx={textFieldStyle} value="first_student_email">
                  <span style={listItemStyle}>Эл. почта студента (первая)</span>
                </MenuItem>
                <MenuItem sx={textFieldStyle} value="first_agent_email">
                  <span style={listItemStyle}>Эл. почта агента (первая)</span>
                </MenuItem>
                <MenuItem sx={textFieldStyle} value="first_representative_email">
                  <span style={listItemStyle}>Эл. почта представителя (первая)</span>
                </MenuItem>
              </TextField>
              <div className="dropChanges">
                {(filterCondition !== "birth_date" && filterCondition !== "registered_in_system") &&
                  <input
                    className="inputStyle"
                    type="text"
                    placeholder={"Введите данные для поиска..."}
                    value={searchingValue}
                    onChange={(event => setSearchingValue(event.target.value))}
                  />
                }
                {(filterCondition === "birth_date" || filterCondition === "registered_in_system") &&
                  <DatePicker
                    open={false}
                    todayButton="Today"
                    selected={typeof (searchingValue) === 'string' ? null : new Date(searchingValue)}
                    onChange={(date) => {
                      setSearchingValue(date === null ? '' : date);
                    }}
                    showMonthDropdown
                    showYearDropdown
                    dateFormat="dd.MM.yyyy"
                    placeholderText={"дд.мм.гггг"}
                    className="date_picker_table"
                    isClearable
                    customInput={
                      <MaskedInput
                        type="text"
                        mask={[/[0-3]/, /\d/, ".", /[0-1]/, /[0-9]/, ".", /[1-2]/, /\d/, /\d/, /\d/]}
                      />
                    }
                  />
                }
                {searchingValue !== '' && <CloseOutlinedIcon
                  sx={{ color: "#ED6C02", cursor: 'pointer', mt: 'auto', mb: 'auto', ml: 1 }}
                  onClick={() => {
                    setSearchingValue('');
                    setFilteredCondition('latin_name');
                  }}
                />}
              </div>
            </div>
          }
        </div>
        {searchType === 'program' &&
          <div className="dropChanges">
            <Box sx={{ mt: -2, mr: 0.5, mb: -2.15 }}>
              <HoursNumberComponent
                initValue={searchingValue}
                setHoursNumber={setSearchingValue}
                isSearchType={true}
              />
            </Box>
            {searchingValue !== '' && searchType !== 'program' && <CloseOutlinedIcon
              sx={{ color: "#ED6C02", cursor: 'pointer', mt: 'auto', mb: 'auto', ml: 1 }}
              onClick={() => setSearchingValue('')}
            />}
          </div>
        }
      </div>
    );
};

export default TableSearchComponent;
