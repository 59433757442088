import React, { useState, useEffect, lazy, Suspense } from 'react';
import { NavLink, useLocation } from "react-router-dom";
import HomeIcon from '@mui/icons-material/Home';
import EmailIcon from '@mui/icons-material/Email';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import jwt_decode from 'jwt-decode';
import { HOME_ROUTE, ACCOUNT_ROUTE } from "../../../utils/consts/pathRoutes";
import { getCountNotifications } from "../../../actions/notification";
import IconButton from "@mui/material/IconButton";
import { Badge } from "@mui/material";
import PersonIcon from '@mui/icons-material/Person';
import { dropToken, getToken } from "../../../utils/token";
import SimCardDownloadRoundedIcon from '@mui/icons-material/SimCardDownloadRounded';
import Tooltip from '@mui/material/Tooltip';
import DownloadGeneratedFilesModal from '../../Modals/DownloadGeneratedFilesModal/DownloadGeneratedFilesModal';

import './Navbar.css';

const ModalCustom = lazy(() => import('../../ModalCustom/ModalCustom'));

export default function Navbar() {
  const [countOfNotifications, setCountOfNotifications] = useState('');
  const [isDownloadGeneratedFiles, setIsDownloadGeneratedFiles] = useState(false);
  const handleOpenDownloadGeneratedFiles = () => {
    setIsDownloadGeneratedFiles(prevState => !prevState);
  };

  let tokenName = '';
  let userName = '';
  let decodedToken = jwt_decode(getToken());
  const IS_TEACHER_ACCESS = decodedToken.role === 'Преподаватель';
  try {
    if (getToken()) {
      tokenName = decodedToken.name.split(' ');
      userName = tokenName.length === 2 ? `${tokenName[0]} ${tokenName[1]}` : tokenName;
    }
  } catch (e) {
    userName = "Не авторизовано";
  }

  useEffect(() => {
    getCountNotifications()
      .then(data => setCountOfNotifications(data));
  }, []);
  const [isActive, setIsActive] = useState(false);

  const path = useLocation().pathname;
  const IS_STUDENT_CARD = path.includes('contract') || path.includes('quota');

  return (
    <section className="navbar">
      {IS_STUDENT_CARD && <div className="downloadGeneratedFiles">
        <Tooltip title="Автоматическое генерирование файлов" placement="top" className="navbar__item">
          <div onClick={handleOpenDownloadGeneratedFiles}>
            <SimCardDownloadRoundedIcon
              sx={{ fontSize: 20 }}
              className="navbar__item"
            />
            Шаблоны файлов
          </div>
        </Tooltip>
      </div>}
      <NavLink to={HOME_ROUTE} className="navbar__item"> <HomeIcon sx={{ fontSize: 20 }} />
        Главная
      </NavLink>
      <div className="account">
        <div className="dropdown_btn_account" onClick={() => setIsActive(!isActive)}>
          {IS_TEACHER_ACCESS
            ?
            <IconButton aria-label={countOfNotifications}>
              <Badge badgeContent={countOfNotifications} color="info">
                <EmailIcon sx={{ fontSize: 18, color: "#FA7A45" }} />
              </Badge>
            </IconButton>
            :
            <NavLink to={ACCOUNT_ROUTE}>
              <IconButton aria-label={countOfNotifications}>
                <Badge badgeContent={countOfNotifications} color="info">
                  <EmailIcon sx={{ fontSize: 18, color: "#FA7A45" }} />
                </Badge>
              </IconButton>
            </NavLink>
          }
          Личный кабинет
        </div>

        {isActive && (
          <div className="dropdown_content_account">
            <div className="user_account">
              <div className="user_name_account">{userName}</div>
            </div>
            {!IS_TEACHER_ACCESS && (
              <NavLink to={ACCOUNT_ROUTE} className="account_button_account">
                <PersonIcon sx={{ fontSize: 19 }} />
                Личный кабинет
              </NavLink>
            )}
            <button
              className="exit_button_account"
              onClick={() => {
                dropToken();
                window.location.reload();
              }}><ExitToAppIcon sx={{ fontSize: 18 }} />
              Выход
            </button>
          </div>
        )}
      </div>
      {
        isDownloadGeneratedFiles &&
        <Suspense fallback={<></>}>
          <ModalCustom active={isDownloadGeneratedFiles} setActive={setIsDownloadGeneratedFiles}>
            <DownloadGeneratedFilesModal />
          </ModalCustom>
        </Suspense>
      }
    </section>
  );
}


